import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Box,
  Title,
  Text,
  Divider,
  Container,
  Icon,
  Button,
} from '@firsttable/web-components/atoms';
import ListItem from '@firsttable/web-components/molecules/ItemNumber';
import { countFormat, roundToClosest, scrollTo } from '@firsttable/functions';
import HeroBackgroundImage from '../components/molecules/HeroBackgroundImage';
import RestaurateursHero from '../components/organisms/RestaurateursHero';
import RestaurateursReview from '../components/organisms/RestaurateursReview';
import Footer from '../components/organisms/Footer';
import ThemeLayout from '../layouts/layout';
import bottle from '../images/bottle.svg';
import ellipse from '../images/ellipse-01.svg';
import SEO from '../components/seo';
import { isMinorSite, isGlobalSite, countryData } from '../helpers/data';
import NavButton from '../components/atoms/NavButton';

const RestaurantCTA = ({ siteConfig, showInMobileOnly }) => {
  let display = ['block'];
  if (showInMobileOnly) {
    display = ['block', null, 'none'];
  }
  const ctaStructure = [1, 1, 1 / 3];
  return (
    <Box display={display}>
      <Container
        mt={[null, null, '-140px']}
        mb={['50px', '50px', '0px']}
        background={[null, null, `url(${ellipse}) no-repeat 0 50%`]}
        pt={[null, null, '175px']}
        pb={[null, null, '135px']}
      >
        <Row gap={0}>
          <Col
            textAlign={['center', 'center', 'left']}
            width={ctaStructure}
            mb={['20px', '20px', 0]}
          >
            <Box position="relative" pr={[null, null, '36px']}>
              <Title size="h4" m={['0 0 20px', '0 0 20px', '0 0 50px']}>
                Request an information pack
              </Title>
              <Text fontSize="xl" mb={0}>
                We’ll send you more information on our story, how First Table
                works and the benefits to your restaurant.
              </Text>
              <Box
                maxWidth="208px"
                mx="auto"
                mt="20px"
                display={['block', 'block', 'none']}
              >
                <Button href={countryData.infopackUrl} kind="cta" size="l" wide>
                  Request Information
                </Button>
              </Box>
            </Box>
          </Col>
          <Col
            textAlign={['center', 'center', 'left']}
            width={ctaStructure}
            mb={['20px', '20px', 0]}
          >
            <Divider display={['block', 'block', 'none']} m={['50px 0']} />
            <Box
              position="relative"
              pl={[null, null, '36px']}
              pr={[null, null, '36px']}
              borderLeft={[null, null, '1px solid']}
              borderColor={[null, null, 'gold.800']}
            >
              <Box
                position="absolute"
                top="-45px"
                left="-10px"
                display={['none', 'none', 'block']}
              >
                <Icon
                  width="28px"
                  name="cutlery"
                  color="gold.800"
                  viewBox="0 0 40 48"
                />
              </Box>
              <Title size="h4" m={['0 0 20px', '0 0 20px', '0 0 50px']}>
                Schedule a personal call
              </Title>
              <Text fontSize="xl" mb={0}>
                Schedule a call with your local rep to learn more about how it
                works and answer any questions.
              </Text>
              <Box
                maxWidth="208px"
                mx="auto"
                mt="20px"
                display={['block', 'block', 'none']}
              >
                <Button
                  NavComponent={NavButton}
                  to={countryData.schedulerLink}
                  kind="cta"
                  size="l"
                  wide
                >
                  Schedule a call
                </Button>
              </Box>
            </Box>
          </Col>
          <Col
            textAlign={['center', 'center', 'left']}
            width={ctaStructure}
            mb={['20px', '20px', 0]}
          >
            <Divider display={['block', 'block', 'none']} m={['50px 0']} />
            <Box
              position="relative"
              pl={[null, null, '36px']}
              borderLeft={[null, null, '1px solid']}
              borderColor={[null, null, 'gold.800']}
            >
              <Box
                position="absolute"
                top="-45px"
                left="-10px"
                display={['none', 'none', 'block']}
              >
                <Icon
                  width="28px"
                  name="cutlery"
                  color="gold.800"
                  viewBox="0 0 40 48"
                />
              </Box>
              <Title size="h4" m={['0 0 20px', '0 0 20px', '0 0 50px']}>
                Sign up your restaurant for free
              </Title>
              <Text fontSize="xl" mb={0}>
                Join over{' '}
                {countFormat(roundToClosest(siteConfig.restaurantCount, 100))}{' '}
                restaurants who are already generating incremental revenue from
                their &rsquo;first tables&rsquo;.
              </Text>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col width={[1 / 3]} display={['none', 'none', 'block']}>
            <Box maxWidth="208px" mx="auto" mt="40px">
              <Button href={countryData.infopackUrl} kind="cta" size="l" wide>
                Request information
              </Button>
            </Box>
          </Col>
          <Col width={ctaStructure} display={['none', 'none', 'block']}>
            <Box maxWidth="208px" mx="auto" mt="40px">
              <Button
                NavComponent={NavButton}
                to={countryData.schedulerLink}
                kind="cta"
                size="l"
                wide
              >
                Schedule a call
              </Button>
            </Box>
          </Col>
          <Col width={ctaStructure}>
            <Box maxWidth="208px" mx="auto" mt="40px">
              <Button href={countryData.signupUrl} kind="cta" size="l" wide>
                Sign up for free
              </Button>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

RestaurantCTA.propTypes = {
  siteConfig: PropTypes.object,
  showInMobileOnly: PropTypes.bool,
};

RestaurantCTA.defaultProps = {
  showInMobileOnly: false,
};

const Restaurants = ({ data: { restaurantsPage, siteConfig } }) => (
  <ThemeLayout footer={<Footer />}>
    <SEO
      id={restaurantsPage.foreignId}
      title={restaurantsPage.metaTitleFormatted}
      description={restaurantsPage.metaDescription}
      ogImage={restaurantsPage.ogImage}
      metaTags={restaurantsPage.metaTags}
      slug="/restaurants/"
    />
    <HeroBackgroundImage
      arrowHelper
      scrollTo={() => scrollTo('HowItWorks', -10)}
    >
      <RestaurateursHero />
    </HeroBackgroundImage>
    <Container>
      <Row>
        <Col>
          <Box
            id="HowItWorks"
            maxWidth="780px"
            textAlign="center"
            mx="auto"
            mb={['60px', '95px']}
            position="relative"
          >
            <Title m="0 0 28px">How it works</Title>
            <Text fontSize="xl" letterSpacing="-0.3px">
              First Table supports restaurateurs by getting their night off to a
              good start. We incentivise customers to dine early, harnessing the
              power of social proof to help your restaurant attract new
              customers from the start of service to end of shift. You show them
              a wonderful time and they’ll tell their friends and return again.
            </Text>
            <Text fontSize="xl" letterSpacing="-0.3px">
              One table is all it takes – we leverage an otherwise empty table
              with a half-price deal on food using the principle of scarcity to
              create a feeling of urgency to book. It’s the perfect incentive
              for diners to try new and exciting restaurants while saving money
              and for restaurants to get those first customers through the door.
            </Text>
            <Text fontSize="xl" letterSpacing="-0.3px">
              Best of all, it&rsquo;s free! There’s no commission, sign-up fees
              or contracts.
            </Text>
            <Box
              position="absolute"
              top="30px"
              right="-180px"
              display={['none', 'none', 'none', 'block']}
            >
              <img src={bottle} alt="" />
            </Box>
          </Box>
          {(!isMinorSite || isGlobalSite) && <Divider mb={['50px', '100px']} />}
        </Col>
      </Row>
      {(!isMinorSite || isGlobalSite) && (
        <RestaurantCTA siteConfig={siteConfig} />
      )}
      <Row>
        <Col>
          <Divider mb={['50px', '100px']} />
          <Title
            color="gold.900"
            textAlign="center"
            m={['0 0 30px', '0 0 50px']}
          >
            10 reasons to join!
          </Title>
          <Box maxWidth="800px" mx="auto">
            {restaurantsPage.blocks.edges.map(({ node }, index) => (
              <ListItem
                key={index}
                num={index + 1}
                title={node.title}
                text={node.content
                  .replace(
                    '[restaurants]',
                    countFormat(
                      roundToClosest(siteConfig.restaurantCount, 100),
                    ),
                  )
                  .replace(
                    '[diners]',
                    countFormat(
                      roundToClosest(
                        Math.max(500000, siteConfig.dinerCount),
                        100000,
                      ),
                    ),
                  )}
              />
            ))}
          </Box>
          <Divider
            m={['50px 0', '50px 0', '90px 0 0']}
            display={['none', 'block', 'block']}
          />
          <RestaurateursReview />
          <Divider m={['50px 0', '50px 0', '0 0 90px']} />
        </Col>
      </Row>
      <RestaurantCTA siteConfig={siteConfig} showInMobileOnly />
    </Container>
  </ThemeLayout>
);

export default Restaurants;

Restaurants.propTypes = {
  data: PropTypes.shape(),
};

export const query = graphql`
  query restaurantsPage {
    siteConfig {
      restaurantCount
      dinerCount
    }
    restaurantsPage: globalPage(slug: { eq: "/restaurants/" }) {
      slug
      title
      className
      metaDescription
      metaTitleFormatted
      metaTags
      ogImage
      foreignId
      blocks {
        edges {
          node {
            id
            title
            content
            sort
          }
        }
      }
    }
    desktop: file(relativePath: { eq: "bg-header-01.jpg" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

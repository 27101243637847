import React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import {
  Divider,
  Box,
  Title,
  Text,
  Button,
  BodyTitle,
} from '@firsttable/web-components/atoms';
import { themeGet, breakpointsObject as bp } from '@firsttable/web-theme';

import bird from '../../../images/bird-01.svg';
import parseHtml from '../../../helpers/parseHtml';
import data from './data';
import { countryData } from '../../../helpers/data';

const ReviewsSlider = styled(Box)`
  .swiper-dots-pagination {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    @media (min-width: ${bp.m}) {
      justify-content: flex-start;
      margin-left: -8px;
    }

    span {
      display: block;
      cursor: pointer;
      padding: 8px;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid ${themeGet('colors.brand.blue')};
      }
    }

    .swiper-pagination-bullet-active::before {
      background: ${themeGet('colors.brand.blue')};
    }
  }
`;

const RestaurateursReview = () => (
  <Box display={['block', null, 'flex']}>
    <Box
      textAlign={['center', null, 'left']}
      width={[null, null, '418px']}
      alignItems="center"
      display={['none', 'flex', 'flex']}
    >
      <Box position="relative">
        <Box
          display={['none', null, 'block']}
          position="absolute"
          top="-66px"
          left="160px"
        >
          <img src={bird} alt="" />
        </Box>
        <Title
          size="h1"
          maxWidth={[null, null, '319px']}
          m={['0 0 30px', null, '0 0 48px']}
        >
          What Restaurants Are Saying
        </Title>
        <Box display={['block', null, 'flex']} alignItems="center">
          <Box m={['0 0 10px', null, '0 30px 0 0']}>
            <Button size="l" kind="cta" href={countryData.signupUrl}>
              Sign up for free
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
    <Divider
      m={['50px 0', null, 0]}
      width={['100%', null, '1px']}
      height={['1px', null, 'auto']}
    />
    <ReviewsSlider
      p={[0, 0, '78px 0 82px 88px']}
      width={[null, null, 'calc(100% - 419px)']}
    >
      <Swiper
        spaceBetween={20}
        autoplay={{
          delay: 7000,
        }}
        pagination={{
          el: '.swiper-dots-pagination',
          clickable: true,
        }}
      >
        {data.map(({ id, name, title, text, userPic }) => (
          <div key={id}>
            <Box
              display={['block', null, 'flex']}
              mb={['s', null, 'm']}
              textAlign={['center', null, 'left']}
            >
              <Box
                mr={['auto', null, '40px']}
                ml={['auto', null, 0]}
                mb={['20px', null, 0]}
                maxWidth={86}
              >
                <Box borderRadius="50%">
                  <img
                    src={userPic}
                    alt=""
                    className="img-fluid img-circle"
                    style={{ borderRadius: '50%' }}
                  />
                </Box>
              </Box>
              <Box>
                <BodyTitle size="xl" m="0 0 7px">
                  {name}
                </BodyTitle>
                <Text fontSize="xl" color="grayscale.600" mb={0}>
                  {title}
                </Text>
              </Box>
            </Box>
            <Box>{parseHtml(text)}</Box>
          </div>
        ))}
      </Swiper>
    </ReviewsSlider>
  </Box>
);

export default RestaurateursReview;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

const Bg = styled.section`
  position: relative;
  ${space};
`;

const Placeholder = styled.div`
  z-index: -2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  &::before {
    content: '';
    display: block;
    height: 100%;
    background: no-repeat url("${({ placeholder }) => placeholder}") 50% 0 / cover;
    filter: blur(15px);
    transform: scale(1.1);
  }
`;

const BgImage = styled.div`
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease 200ms;
  background: no-repeat url("${({ bg }) => bg}") 50% 0 / cover;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const DarkGradient = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
`;

class BackgroundImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    const { imageData: { src } } = this.props;

    if (this.isImageCached(src)) {
      this.setImageLoaded();
    } else {
      this.preloadImage(src).then(() => {
        this.setImageLoaded();
      });
    }
  }

  setImageLoaded = () => {
    this.setState({
      isLoaded: true,
    });
  };

  preloadImage = (src) => new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;
    image.src = src;
  });

  isImageCached = (src) => {
    const image = new Image();
    image.src = src;
    return image.complete;
  };

  render() {
    const { imageData, children, ...props } = this.props;
    const { isLoaded } = this.state;
    const { base64, src } = imageData;

    return (
      <Bg {...props}>
        <Placeholder placeholder={base64} />
        <BgImage isLoaded={isLoaded} bg={src} />
        <DarkGradient />
        {children}
      </Bg>
    );
  }
}

BackgroundImage.propTypes = {
  imageData: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default BackgroundImage;

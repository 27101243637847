import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import styled, { keyframes, css } from 'styled-components';
import { Box, Icon } from '@firsttable/web-components/atoms';
import BackgroundImage from '@firsttable/web-components/molecules/BackgroundImage';
import MainMenu from '../../organisms/Navigation/Menu';

const StyledImageBackground = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        cityDesktop: file(relativePath: { eq: "bg-header-01.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1920, cropFocus: NORTH) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.cityDesktop.childImageSharp.fluid;
      return (
        <BackgroundImage imageData={imageData} {...props}>
          {children}
        </BackgroundImage>
      );
    }}
  />
);
StyledImageBackground.propTypes = {
  children: PropTypes.node,
};

const HeaderWave = styled(Box)`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 140px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  @media (min-width: 1600px) {
    height: 100%;
  }
`;

const chevronAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const Chevron = styled(Box)`
  position: absolute;
  bottom: 150px;
  left: 50%;
  width: 28px;
  height: 28px;
  margin-left: -14px;
  animation: 2s ${chevronAnimation} ease-in-out infinite;
  ${({ onClick }) =>
    typeof onClick === 'function'
      ? css`
          cursor: pointer;
        `
      : null};
`;

const HeroBackgroundImage = ({
  children,
  arrowHelper,
  scrollTo,
  showMenu,
  ...props
}) => (
  <StyledImageBackground mb={['xxl', 96]} pb={[72, 140]} {...props}>
    {showMenu && <MainMenu transparent mb={['xxl', 70]} />}
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      minHeight={[null, 470]}
    >
      <Box flex={1}>{children}</Box>
    </Box>
    <HeaderWave>
      <svg
        width="100%"
        viewBox="0 0 1600 86"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M800 54.0001C186 145 0 14.0001 0 14.0001V154H1600V14.0001C1600 14.0001 1555.81 1.88336 1439.4 0H1376.03C1251.01 1.84955 1065.53 14.6465 800 54.0001Z"
          fill="#fff"
        />
      </svg>
    </HeaderWave>
    {arrowHelper && (
      <Chevron display={['none', 'block']} onClick={scrollTo}>
        <Icon name="nav-arrow-down" color="white" />
      </Chevron>
    )}
  </StyledImageBackground>
);

HeroBackgroundImage.propTypes = {
  children: PropTypes.node,
  arrowHelper: PropTypes.bool,
  scrollTo: PropTypes.func,
  showMenu: PropTypes.bool,
};

HeroBackgroundImage.defaultProps = {
  scrollTo: null,
  showMenu: true,
};

export default HeroBackgroundImage;

import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../atoms/Box';
import Number from '../../atoms/Number';
import Title from '../../atoms/Title';

const ItemNumber = ({ num, title, text }) => (
  <Box mb={[0, '50px']}>
    <Number
      position="relative"
      num={num}
      mb="-120px"
      ml={[null, '-40px']}
      top={['-15px', 0]}
    />
    <Box position="relative">
      <Box>
        <Title as="h3" size="h4" m="0 0 11px">{title}</Title>
        {/* eslint-disable-next-line react/no-danger */}
        <><div dangerouslySetInnerHTML={{ __html: text }} /></>
      </Box>
    </Box>
  </Box>
);

ItemNumber.propTypes = {
  num: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
};

ItemNumber.defaultProps = {
  num: 0,
  title: '',
  text: '',
};

export default ItemNumber;

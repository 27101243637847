import React from 'react';
import {
  Container, Title, Text, Button,
} from '@firsttable/web-components/atoms';
import { countryData } from '../../../helpers/data';

const RestaurateursHero = () => (
  <Container maxWidth={758} color="#fff" textAlign="center">
    <Title as="h1" size="hero" m="0 0 20px" color={null}>Promote Your Restaurant</Title>
    <Text fontSize="xl" mb="xl">
      First Table is a restaurant marketing company helping restaurants use their
      {' '}
      &lsquo;first tables&rsquo; as a promotional tool to attract more customers
      {' '}
      and increase revenue.
    </Text>
    <Button kind="cta" size="l" mb="m" href={countryData.signupUrl} data-testid="restaurants__signup-btn">Sign up for free</Button>
  </Container>
);

RestaurateursHero.propTypes = {};

export default RestaurateursHero;

import jshLogo from './jsh-logo.jpg';
import commonsLogo from './commons-logo.jpg';
import ormeggioLogo from './ormeggio-logo.jpg';
import piluLogo from './pilu-logo.jpg';

const reviews = [
  {
    id: 1,
    name: 'Hayden Davison',
    userPic: jshLogo,
    title: 'Restaurant Manager at Jervois Steak House',
    text: `<p>First Table has definitely been a great marketing tool for us. It’s a brilliant way for our local market to try us for a nice
dinner out without breaking the bank.</p>

<p>Early dining is always a quiet time of night so it’s great being able to create some real atmosphere in the restaurant at an early stage of
the evening. It’s also a great opportunity for our staff to show the First Table diners what we do here and add a personal touch to their experience.
We get a lot of return customers as a result.</p>`,
  },
  {
    id: 2,
    name: 'Deahla Stockman',
    userPic: commonsLogo,
    title: 'Restaurant Manager at The Commons Auckland',
    text: `<p>First Table has helped to open our doors to customers that might not have thought there was classic European food available in the North Shore.</p>

<p>Through the deals provided by First Table, guests feel more relaxed to explore our menu and try dishes they wouldn’t normally eat. From this, we have seen an
influx in repeat diners who want to bring their friends and family in to discover the same quality experience they had at The Commons.</p>`,
  },
  {
    id: 3,
    name: 'Taryn Sirkka',
    userPic: ormeggioLogo,
    title: 'Digital Marketing Manager at Ormeggio',
    text: `<p>First Table is a great way to attract new customers that have never heard of our restaurant before, and fill up our early seating times that would
normally be empty.</p>

<p>It allows us to reseat a second booking after the First Table customers have finished, and also creates a buzzing atmosphere at a time of the service that
 would usually be silent!<br /><br /></p>`,
  },
  {
    id: 4,
    name: 'Alex Tuckett',
    userPic: piluLogo,
    title: 'Restaurant Manager at Pilu at Freshwater Sydney',
    text: `<p>
First Table has allowed us to reach a wider audience, enabling diners that may not have thought to dine with
us previously the chance to experience Pilu at Freshwater at a lesser cost.</p>

<p>Our hope is that diners will enjoy a great experience and return to celebrate future special occasions.
The majority of diners involved with First Table reside in our local community, which has allowed strong word of mouth promotion.
</p>`,
  },
];

export default reviews;
